<template>
  <el-container direction="vertical">
    <el-row justify="space-between" align="middle">
      <el-col :span="4"><h3>Catégories </h3></el-col>
      <el-col :span="6">
        <el-space alignment="flex-end">
          <el-input v-model="search" clearable placeholder="Type to search" />
          <category-dialog :action="'create'" />
        </el-space>
      </el-col>
    </el-row>
    <el-table :data="list" style="width: 100%">
      <el-table-column label="Name" prop="name" />
      <el-table-column label="Actions" style="text-align: right;" width="100">
        <template #default="scope">
          <el-space>
            <category-dialog :action="'edit'" :row="scope.row" />
            <el-popconfirm
              confirmButtonText="Oui"
              cancelButtonText="Annuler"
              @confirm="handleDelete(scope.row)"
              :icon="InfoIcon"
              iconColor="#F56C6C"
              title="Voulez vous vraiment supprimer ce boitier ?"
            >
              <template #reference>
                <el-button size="small" type="danger" :icon="DeleteIcon" />
              </template>
            </el-popconfirm>
          </el-space>
        </template>
      </el-table-column>
    </el-table>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import CategoryDialog from './Dialog.vue'
export default {
  components: { CategoryDialog },

  data() {
    return { search: '' }
  },
  computed: {
    ...mapState('categories', ['list']),
  },
  mounted() {},
  methods: {
    handleDelete(row) {
      this.$store.dispatch('categories/deleteItem', { id: row.id })
    },
  },
}
</script>

<style></style>
