<template>
  <span v-if="isCreateForm">
    <el-button type="primary" :icon="PlusIcon" @click="handleOpen"
      >Ajouter</el-button
    >
  </span>
  <span v-else>
    <el-button :icon="EditIcon" size="small" @click="handleOpen"></el-button>
  </span>

  <el-dialog
    v-model="dialogVisible"
    title="Formulaire"
    width="30%"
    
    append-to-body
  >
    <el-form ref="categoryForm" :model="form" :rules="rules">
      <el-form-item label="Nom " prop="name">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">Annuler</el-button>
        <el-button
          v-if="isCreateForm"
          type="primary"
          @click="create('categoryForm')"
          >Confirmer</el-button
        >
        <el-button v-else type="primary" @click="update('categoryForm')"
          >Confirmer</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      default: 'create',
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      formLabelWidth: '150px',
      form: {
        name: null,
        sort_order: null,
      },
      confirmPassword: null,
      rules: {
        name: [
          {
            required: true,
            message: 'Veuillez saisir le nom',
            trigger: 'change',
          },
        ],
        sort_order: [
          {
            required: true,
            message: "Veuillez saisir l'ordre",
            trigger: 'change',
          },
        ],
      },
    }
  },
  computed: {
    isCreateForm() {
      if (this.action == 'create') {
        return true
      } else return false
    },
  },
  methods: {
    handleOpen() {
      this.dialogVisible = true
      if (this.action == 'edit') this.populate(this.row)
    },
    populate(row) {
      this.form.name = row.name
      this.form.sort_order = row.sort_order
    },
    create(categoryForm) {
      this.$refs[categoryForm].validate((valid) => {
        if (valid) {
          let payload = {
            data: {
              ...this.form,
            },
          }
          this.$store
            .dispatch('categories/addItem', payload)
            .then(() => {
              this.dialogVisible = false
            })
            .then(() => {
              this.form.name = ''
            })
        } else return false
      })
    },
    update(categoryForm) {
      this.$refs[categoryForm].validate((valid) => {
        if (valid) {
          let payload = {
            id: this.row.id,
            data: {
              ...this.form,
            },
          }
          this.$store.dispatch('categories/updateItem', payload).then(() => {
            this.dialogVisible = false
          })
        } else return false
      })
    },
  },
}
</script>

<style></style>
