<template>
  <categories-table></categories-table>
</template>

<script>
import CategoriesTable from './Table.vue'
export default {
  components: { CategoriesTable },
  data() {
    return {}
  },
  mounted() {
    this.$store.dispatch('categories/getList', {})
  },
  methods: {},
}
</script>

<style></style>
